html, button {
  font-family: Montserrat;
}

button {
  border-width: 0;
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

sub {
  position: relative;
  top: 0.16em;
  font-size: 0.5em;
}

sup {
  position: relative;
  top: -0.45em;
  font-size: 0.7em;
}

*:focus {
  outline: 2px solid #d71ef7;
}

/* hack to try and force Korean subtitles to only
 * break at "word" boundaries (in the Wistia video
 * player) */
.w-captions-line {
  word-break: keep-all;
}
